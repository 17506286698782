import React from "react";
import { useEffect } from "react";
import { isAndroid, isIOS } from "react-device-detect";
import Welcome from "./Welcome";
import { useParams } from "react-router-dom";

export default function Home() {
  const { id } = useParams();
  console.log(id);
  useEffect(() => {
    if (isAndroid) {
      const url = `intent://gunjanapp.com/${id}#Intent;scheme=http;package=app.gunjan;action=android.intent.action.VIEW;end`;

      window.location.replace(url);
    } else if (isIOS) {
      //   window.location.replace("instagram://");
      //   setTimeout(() => {
      //     window.location.replace(
      //       "https://apps.apple.com/us/app/instagram/id389801289"
      //     );
      //   }, 10000);
    } else {
    //   window.location.replace(
    //     "https://play.google.com/store/apps/details?id=app.gunjan"
    //   );
    }
  }, [id]);
  return (
    <div className="App-header">
      {isAndroid ? (
        <>
          {" "}
          <div>
            If you have not been automatically redirected, click on the
            following link:
          </div>
          <a href="https://play.google.com/store/apps/details?id=app.gunjan">
            Open Android app
          </a>
        </>
      ) : isIOS ? (
        <h2>IOS App not available</h2>
      ) : (
        <>
          {" "}
          <div>
            If you have not been automatically redirected, click on the
            following link:
          </div>
          <a href="https://play.google.com/store/apps/details?id=app.gunjan">
            Open Android app
          </a>
        </>
      )}
    </div>
  );
}
